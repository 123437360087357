
google.charts.load('current', {'packages':['corechart']});
// Регистрации в ГРСИ по годам
google.charts.setOnLoadCallback(drawGrsiRegistraions);
function drawGrsiRegistraions() {
	if ($('#chart-grsi-registrations').length) {
		$.get( "/charts/grsi-registrations", function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
				/*title : 'Статистика ГРСИ РФ',*/
				vAxis: {title: 'Регистрации в год'},
				hAxis: {title: null},
				seriesType: 'bars',
				series: {1: {type: 'line',targetAxisIndex:1}},
				vAxes: {
					1: {
					  title:'Всего приборов',
					  textStyle: {color: 'red'}
					}
				},
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
		    };
			var chart = new google.visualization.ComboChart(document.getElementById('chart-grsi-registrations'));
		    chart.draw(data, options);
		});
	}
};

// Регистрации в ГРСИ по годам (Фильтрация по группе)
google.charts.setOnLoadCallback(drawGrsiRegistraionsGroup);
function drawGrsiRegistraionsGroup() {
	if ($('#chart-grsi-registrations-group').length) {
		var group_id = $('#chart-grsi-registrations-group').attr('group');
		$.get( "/charts/grsi-registrations?group="+group_id, function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
				vAxis: {title: 'Регистрации в год'},
				hAxis: {title: null},
				seriesType: 'bars',
				series: {1: {type: 'line',targetAxisIndex:1}},
				vAxes: {
					1: {
					  title:'Всего приборов',
					  textStyle: {color: 'red'}
					}
				},
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
		    };
			var chart = new google.visualization.ComboChart(document.getElementById('chart-grsi-registrations-group'));
		    chart.draw(data, options);
		});
	}
};

// Регистрации в ГРСИ по годам (Фильтрация по городу)
google.charts.setOnLoadCallback(drawGrsiRegistraionsCity);
function drawGrsiRegistraionsCity() {
	if ($('#chart-grsi-registrations-city').length) {
		var city_id = $('#chart-grsi-registrations-city').attr('city');
		$.get( "/charts/grsi-registrations?city="+city_id, function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
				vAxis: {title: 'Регистрации в год'},
				hAxis: {title: null},
				seriesType: 'bars',
				series: {1: {type: 'line',targetAxisIndex:1}},
				vAxes: {
					1: {
					  title:'Всего приборов',
					  textStyle: {color: 'red'}
					}
				},
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
		    };
			var chart = new google.visualization.ComboChart(document.getElementById('chart-grsi-registrations-city'));
		    chart.draw(data, options);
		});
	}
};

// Регистрации в ГРСИ по годам (Фильтрация по компании)
google.charts.setOnLoadCallback(drawGrsiRegistraionsCompany);
function drawGrsiRegistraionsCompany() {
	if ($('#chart-grsi-registrations-company').length) {
		var company_id = $('#chart-grsi-registrations-company').attr('company');
		$.get( "/charts/grsi-registrations?company="+company_id, function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
				vAxis: {title: 'Регистрации в год'},
				hAxis: {title: null},
				seriesType: 'bars',
				series: {1: {type: 'line',targetAxisIndex:1}},
				vAxes: {
					1: {
					  title:'Всего приборов',
					  textStyle: {color: 'red'}
					}
				},
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
		    };
			var chart = new google.visualization.ComboChart(document.getElementById('chart-grsi-registrations-company'));
		    chart.draw(data, options);
		});
	}
};

// Распределение СИ по группам
google.charts.setOnLoadCallback(drawGroupsCompany);
function drawGroupsCompany() {
	if ($('#chart-groups-company').length) {
		var company_id = $('#chart-groups-company').attr('company');
		$.get( "/charts/groups?company="+company_id, function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
	        	title: 'ТОП 10 по типам СИ',
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
	        };
			var chart = new google.visualization.PieChart(document.getElementById('chart-groups-company'));
        	chart.draw(data, options);
		});
	}
};

// Распределение СИ по группам (Фильтрация по компании)
google.charts.setOnLoadCallback(drawGroups);
function drawGroups() {
	if ($('#chart-groups').length) {
		$.get( "/charts/groups", function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
	        	title: 'ТОП 10 по типам СИ',
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
	        };
			var chart = new google.visualization.PieChart(document.getElementById('chart-groups'));
        	chart.draw(data, options);
		});
	}
};

// Статистика поверок
google.charts.setOnLoadCallback(drawVerifications);
function drawVerifications() {
	if ($('#chart-verifications').length) {
		let model_id = $('#chart-verifications').attr('model_id');
		let model_type = $('#chart-verifications').attr('model_type');
		$.get("/charts/verifications/"+model_type+"/"+model_id, function( jsonData ) {
			var data = new google.visualization.DataTable(jsonData);
			var options = {
				vAxis: {title: 'Поверок по месяцам'},
				hAxis: {title: null},
				seriesType: 'bars',
				series: {1: {type: 'line',targetAxisIndex:1}},
				vAxes: {
					1: {
					  title:'Всего поверено',
					  textStyle: {color: 'red'}
					}
				},
				animation:{
					duration: 1000,
					easing: 'out',
					startup: true
				}
		    };
			var formatter = new google.visualization.NumberFormat({ groupingSymbol: ' ', fractionDigits: 0 });
    		formatter.format(data, 1);

			var chart = new google.visualization.ComboChart(document.getElementById('chart-verifications'));
		    chart.draw(data, options);
		});
	}
};
